import * as React from "react"
import { graphql } from "gatsby"
import { linkResolver } from '../utils/link_resolver'
import Seo from '../components/seo'
import Footer from '../components/footer'
import Header from "../components/header"
import Contact from "../components/contact"
import Heading from "../components/heading"
import TextImageAndVideo from "../components/text_image_and_video"
import Experts from "../components/experts"
import Brands from "../components/brands"

const CommercialRefrigerationPage = ({data}) => {
  const entry = data.prismicCommercialRefrigerationPage
  if (!entry) return null
  return (
    <>
      <Seo title={entry.data.page_title} description={entry.data.meta_description} image={entry.data.meta_image.url} pathname={linkResolver(entry.data)} />
      <Header logo='ref_air' />
      {entry.data.body.map((slice) => {
        if (slice.slice_type === 'contact') {
          return (
            <div key={slice.id}>
              <Contact primary={slice.primary} />
            </div>
          )
        }
        if (slice.slice_type === 'brands') {
          return (
            <div key={slice.id}>
              <Brands primary={slice.primary} items={slice.items} />
            </div>
          )
        }
        if (slice.slice_type === 'heading') {
          return (
            <div key={slice.id}>
              <Heading primary={slice.primary} />
            </div>
          )
        }
        if (slice.slice_type === 'text_image_and_video') {
          return (
            <div key={slice.id}>
              <TextImageAndVideo primary={slice.primary} />
            </div>
          )
        }
        if (slice.slice_type === 'experts') {
          return (
            <div key={slice.id}>
              <Experts items={slice.items} />
            </div>
          )
        }
      })}
      <Footer />
    </>
  )
}

export const query = graphql`
query {
  prismicCommercialRefrigerationPage {
    data {
      meta_description
      page_title
      meta_image {
        url
      }
      body {
        ... on PrismicCommercialRefrigerationPageDataBodyBrands {
          id
          items {
            image1 {
              gatsbyImageData
              alt
            }
          }
          primary {
            heading1
            text1 {
              richText
            }
          }
          slice_type
        }
        ... on PrismicCommercialRefrigerationPageDataBodyContact {
          id
          slice_type
          primary {
            heading1
            text1 {
              richText
            }
            background {
              gatsbyImageData
              alt
            }
          }
        }
        ... on PrismicCommercialRefrigerationPageDataBodyHeading {
          id
          slice_type
          primary {
            breadcrumb
            button1
            heading1
            text1 {
              richText
            }
            page_link1 {
              url
              uid
              type
            }
            parent {
              url
              uid
              type
              link_type
            }
            image1 {
              alt
              gatsbyImageData
            }
          }
        }
        ... on PrismicCommercialRefrigerationPageDataBodyTextImageAndVideo {
          id
          slice_type
          primary {
            button_link
            button_pdf
            video
            text1 {
              richText
            }
            pdf {
              url
              uid
              type
              localFile {
                publicURL
              }
              link_type
            }
            page_link1 {
              url
              uid
              type
            }
            image1 {
              alt
              gatsbyImageData
            }
          }
        }
        ... on PrismicCommercialRefrigerationPageDataBodyExperts {
          id
          slice_type
          items {
            name
            position
            image {
              gatsbyImageData
              alt
            }
            phone
            email
          }
        }
      }
    }
  }
}
`

export default CommercialRefrigerationPage
